import * as React from "react";
import { Component } from "react";
import HTML5Video from "../global/video.js";
import VideoSrc from "../../video/about.mp4";
import ImprovingAccess from "../../images/About-us-access.png";
import ImprovingAccessMobile from "../../images/About-us-access-mobile.png";
import PressPlay from "../../images/press-play.png";
import StopTheVirus from "../../images/stop-the-virus.png";
import HealthySexual from "../../images/healthy-sexual.png";
import CorporateGiving from "../../images/About-us-giving.png";
import CorporateGivingMobile from "../../images/About-us-giving-mobile.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";
import $ from "jquery";

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleShow = (show) => {
    this.setState({ show: true });
  };
  componentDidMount = () => {
    $("[data-jump-link").on("click", (e) => {
      let destination = $(e.currentTarget).attr("data-jump-link");
      let destinationElement = $(
        '[data-jump-destination="' + destination + '"]'
      );
      let position = $(destinationElement).offset().top;
      let offset = $(window).width() <= 574 ? 100 : 30;
      window.scrollTo({ top: position - offset, behavior: "smooth" });
    });
  };

  render() {
    return (
      <div
        className={`
        ${this.props.show ? "d-block" : "d-none"}
        about
        `}
      >
        <Container
          fluid
          className="hero about"
          role="img"
          aria-label="Group of people
                      with different
                      facial expressions"
        />
        <Container className="px-0">
          <Row>
            <Col xs={10} className="mx-auto">
              <div className="hero-callout mt-0 muted-yellow-dark">
                <h1 className="title">
                  What we live for<sup>&reg;</sup>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="px-0">
          <Row>
            <Col xs={10} className="mx-auto pt-0">
              <Container>
                <Row>
                  <Col xs={12} className="p-0">
                    <p>
                      While there is no cure for HIV, we’re committed to meeting
                      the needs of patients and helping improve the lives of the
                      37.7 million people living with HIV around the world and
                      the many more at risk for HIV.<sup>1</sup> For more than
                      three decades, Gilead Sciences has pioneered research and
                      development of HIV therapies across the care continuum,
                      establishing ourselves within the HIV landscape. We've
                      developed a number of commercially available medications
                      for both the treatment and prevention of HIV, and are
                      advancing a pipeline of investigational therapeutic
                      options. We're committed to helping{" "}
                      <a
                        className="text-link"
                        href="/landscape/ehe"
                      >
                        end the HIV epidemic
                      </a>{" "}
                      and helping all those living with HIV live longer,
                      healthier lives.
                    </p>
                    <figure className="pt-3 mt-3">
                      <HTML5Video
                        handleClose={this.handleClose}
                        handleShow={this.handleShow}
                        show={this.state.show}
                        thumbnail={"/what-we-live-for-thumbnail-updated.png"}
                        url={VideoSrc}
                        videoTrackLabel="What We Live For"
                      />

                      <img
                        onClick={this.handleShow}
                        src={"/what-we-live-for-thumbnail-updated.png"}
                        alt="Video Thumbnail: GILEAD HIV What We Live For"
                        className="video w-100 mb-2"
                      />
                      <figcaption className="font-italic font-20 font-18-mobile pt-1 pb-3 color-black">
                        Watch to learn about the ways we’re working alongside
                        the HIV community to help put HIV and its impact in the
                        past.
                      </figcaption>
                    </figure>
                    <p>
                      Medicines have advanced since the HIV epidemic first
                      started, but we know medicine alone cannot end the
                      epidemic. It takes working alongside medical and patient
                      communities alike because it will take all of us to be a
                      part of the solution. We recognize that patients must have
                      access to care in order for it to be effective. We believe
                      working side by side with HIV communities creates lasting
                      change. Together we’ve come a long way, but there is still
                      much{" "}
                      <a
                        className="text-link" href="/landscape/state-of-epidemic"
                      >
                        left to do
                      </a>
                      . Learn more below about our commitments to the HIV
                      community under the following tenets:
                    </p>
                    <div className="tag-row about">
                      <div
                        className="tag"
                        data-jump-link="pioneering-science"
                      >
                        Pioneering Science & Innovation
                      </div>
                      <div
                        className="tag"
                        data-jump-link="improving-access"
                      >
                        Improving Access to Care
                      </div>
                      <div
                        className="tag"
                        data-jump-link="corporate-giving"
                      >
                        Corporate Giving
                      </div>
                      <div
                        className="tag"
                        data-jump-link="providing-education"
                      >
                        Providing Education
                      </div>
                    </div>
                    <div
                      className="tag mt-0 mt-md-3" data-jump-link="fostering-community"
                    >
                      Fostering Community Through Partnership & SUPPORT
                    </div>
                  </Col>
                  <Col xs={12} className="section">
                    <h2
                      data-jump-destination="pioneering-science"
                      className="title"
                    >
                      Pioneering Science <br className="d-block d-md-none" />&
                      Innovation
                    </h2>
                    <Container className="px-0">
                      <Row>
                        <Col xs={12}>
                          <p>
                            We have been committed to leading advancements in
                            medicine since the{" "}
                            <a
                              className="text-link" href="/landscape/history-of-hiv"
                            >
                              beginning of the HIV epidemic
                            </a>
                            . Early on, HIV treatments were complex and
                            burdensome, sometimes requiring more than 20 pills a
                            day.<sup>2</sup> Recognizing this unmet need, we
                            worked to develop treatments that were one or two
                            pills a day. We continue to strive to meet the needs
                            of our patients. We've developed multiple therapies
                            for the treatment of HIV. For those at risk of HIV,
                            we launched medicines used as pre-exposure
                            prophylaxis (PrEP) to help prevent HIV transmission.
                          </p>
                          <p>
                            Although HIV is now considered a chronic, manageable
                            disease, there is no cure for HIV.<sup>1,3</sup> We
                            are committed to the ongoing pursuit of a cure to
                            help put HIV and its impact in the past. Building on
                            our history of curing difficult diseases, we are
                            working to find a cure for HIV in our own labs and
                            with more than 100 research partners.
                          </p>
                          <p className="mb-0">
                            We continue to focus on{" "}
                            <a
                              className="text-link" href="/landscape/hiv-testing"
                            >
                              increasing HIV testing
                            </a>{" "}
                            and innovating across the{" "}
                            <a
                              className="text-link" href="/landscape/hiv-care-continuum"
                            >
                              HIV care continuum
                            </a>{" "}
                            with the hopes that, when coupled with dismantling
                            systemic barriers to care, the age of HIV can one
                            day come to an end.
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col xs={12} className="section">
                    <h2
                      data-jump-destination="improving-access"
                      className="title"
                    >
                      Improving Access <br className="d-block d-md-none" />
                      to Care
                    </h2>
                    <img
                      className="mx-auto pb-3 mb-1 d-none d-md-block"
                      src={ImprovingAccess}
                      alt="Group of people
                            standing outside in
                            a city"
                    />
                    <img
                      className="mx-auto pb-3 mb-1 d-block d-md-none w-85"
                      src={ImprovingAccessMobile}
                      alt="Group of people
                            standing outside in
                            a city"
                    />
                    <p>
                      We are passionate about helping get medicines to the
                      people and communities that need them. As part of the
                      global Medicines Patent Pool, we provide our products
                      through a manufacturing partner to over 12 million people
                      in resource-limited countries. By working with our
                      community partners, we are helping to provide access to
                      HIV medicines across 140 countries.
                    </p>
                    <p>
                      In the United States, the Gilead{" "}
                      <a
                        className="text-link" href="https://www.gileadadvancingaccess.com"
                      >
                        Advancing Access
                      </a>
                      <sup>®</sup> program is committed to helping eligible
                      individuals, whether they are insured or uninsured,
                      understand their coverage and identify financial support
                      options available to them. Through the Advancing Access
                      Patient Assistance Program, eligible patients without
                      insurance may be able to receive their prescribed Gilead
                      HIV medication free of charge. For individuals who have
                      commercial insurance, but need help paying for their
                      out-of-pocket costs, the Advancing Access Co-pay Coupon
                      Program* provides co-pay assistance to eligible patients
                      for their prescribed HIV medications.
                    </p>
                    <p className="mb-0">
                      We also believe access constitutes more than financial
                      assistance alone. People living with HIV have historically
                      faced a wide range of systemic barriers that stand in the
                      way of obtaining equitable care.<sup>4</sup> Race, sexual
                      identity, geographic location, and socioeconomic status
                      are among many factors across which we see vast,
                      persistent health disparities caused by stigma, racism,
                      unequal access to healthcare, and other barriers.
                      <sup>5</sup> We have adopted a thoughtful approach to
                      addressing barriers to HIV care, working to expose and
                      remove the underlying social and systemic barriers to care
                      through HIV education, policy reform, and grassroots
                      activism.
                    </p>
                    <br />
                    <span className="cont-hang footnote font-16 font-light">
                      <span className="hang">*</span>
                      For commercially insured eligible patients only.
                      Restrictions apply. See terms and conditions at{" "}
                      <a
                        className="underline" href="https://www.GileadAdvancingAccess.com"
                      >
                        www.GileadAdvancingAccess.com
                      </a>
                    </span>
                  </Col>
                  <Col xs={12} className="section corporate-giving">
                    <h2
                      data-jump-destination="corporate-giving"
                      className="title text-uppercase "
                    >
                      Corporate Giving
                    </h2>
                    <img
                      className="d-none d-md-block mx-auto pb-3 mb-1 w-85"
                      src={CorporateGiving}
                      alt="Person looking off
                          in to the distance"
                    />
                    <img
                      className="d-block d-md-none mx-auto pb-3 mb-1 w-85"
                      src={CorporateGivingMobile}
                      alt="Person looking off
                          in to the distance"
                    />
                    <p>
                      We recognize the importance of addressing the barriers to
                      care and the importance of building communities from
                      within. This is why, through our grantee programs, we
                      support patient advocates, healthcare professionals, and
                      nonprofit groups around the world that work tirelessly in
                      local communities to help improve the lives of people
                      affected by HIV. In 2019, we:
                    </p>
                    <ul className="mb-0">
                      <li>
                        Supported{" "}
                        <span className="font-heavy font-red">
                          2000+ organizations
                        </span>{" "}
                        that are addressing stigma, health, and racial
                        disparities
                      </li>
                      <li>
                        Provided over{" "}
                        <span className="font-heavy font-red">$380M</span> in
                        cash donations
                      </li>
                      <li>
                        Were named{" "}
                        <span className="font-heavy font-red">
                          top corporate philanthropic funder
                        </span>{" "}
                        of global HIV/AIDS research and care, second overall to
                        the Bill & Melinda Gates Foundation
                      </li>
                      <li>
                        Donated{" "}
                        <span className="font-heavy font-red">2.4 million</span>{" "}
                        bottles of PrEP medicines to the CDC to be provided to
                        patients at no cost—an ongoing investment through 2030
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className="section mb-0 mb-md-4 pb-2 pb-md-4">
                    <h2
                      data-jump-destination="providing-education"
                      className="title text-uppercase "
                    >
                      PROVIDING EDUCATION
                    </h2>
                    <p>
                      We consider education to be key to helping end the HIV
                      epidemic. Believing that it is important to start
                      educational work from the ground up, we created a team of
                      Community Liaisons to work with community organizations
                      and healthcare providers at the local level, taking the
                      time to understand the needs of each community and provide
                      tailored HIV educational resources to help end the HIV
                      epidemic.
                    </p>
                    <p>
                      In addition, knowledge-sharing between leaders can help us
                      support one another in our common goal of helping end the
                      HIV epidemic. We have, therefore, created educational
                      speaker programs aimed at addressing emerging trends and
                      issues impacting the HIV epidemic to provoke thought
                      leadership in the HIV space, including:
                    </p>
                    <ul>
                      <li>
                        <span className="font-heavy font-red">
                          Blueprints for Success Training Institutes
                        </span>
                        : A series of skill-building trainings for leaders in
                        HIV to help equip them with the knowledge and tools to
                        address new challenges around HIV care and prevention.
                        Examples of topics covered include Cultural Humility,
                        Institutional Stigma, and Telemedicine
                      </li>
                      <li>
                        <span className="font-heavy font-red">
                          Community Speaker Series
                        </span>
                        : A platform for emerging leaders to share their vision
                        and insight, largely focused on eradicating the stigma
                        around HIV and illuminating the social determinants of
                        health that contribute to the disproportionate impact of
                        HIV on specific populations
                      </li>
                    </ul>
                    <p>
                      We’ve created educational sites that feature programs
                      designed to educate and share facts about HIV to help
                      people protect themselves and each other, including:
                    </p>
                  </Col>
                </Row>

                <Row className="justify-content-between">
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0 mb-4 mb-md-0"
                  >
                    <a
                      target="_blank"
                      href="https://www.hivtestnow.com">
                      <img className="pb-3" src={PressPlay} />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          HIV Test Now
                        </span>
                        HIV testing is an important part of self-care. HIV Test
                        Now is an educational resource to help people get tested
                        and know their HIV status.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0 mb-4 mb-md-0"
                  >
                    <a
                      target="_blank"
                      href="https://www.helpstopthevirus.com">
                      <img className="pb-3" src={StopTheVirus} />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          Help Stop the Virus
                        </span>
                        HIV education for everyone, showing how prevention,
                        testing, and treatment work together to help stop HIV in
                        our bodies and communities, featuring videos and other
                        resources.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0 mb-4 mb-md-0 "
                  >
                    <a
                      target="_blank"
                      href="https://www.healthysexuals.com/">
                      <img className="pb-3" src={HealthySexual} />
                      <p>
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">Healthysexual</span>
                          <sup>&reg;</sup>
                        </span>
                        Teaching individuals how to protect their sexual health
                        through tips and other materials, with a focus on
                        everyone knowing about their HIV prevention options.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0"
                  />
                </Row>

                <Row>
                  <Col xs={12} className="section mb-4 pb-4 px-0">
                    <h2
                      data-jump-destination="fostering-community"
                      className="title text-uppercase "
                    >
                      FOSTERING COMMUNITY THROUGH PARTNERSHIP & SUPPORT
                    </h2>
                    <p>
                      None of us can do this work alone—seeing the age of HIV
                      come to an end is a collective mission. That’s why we work
                      closely with healthcare providers, researchers, academics,
                      policymakers, advocates, activists, people living with
                      HIV, and people at risk for HIV.
                    </p>
                    <p>
                      Working with the community toward our collective, common
                      goal of ending the HIV epidemic, we’re supporting the
                      innovative work of grassroots organizations to help
                      improve access to care by eliminating barriers and
                      advancing education. Below are some of our key
                      partnerships and initiatives in HIV:
                    </p>
                  </Col>
                </Row>

                <Row className="justify-content-between mb-0 mb-md-4">
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a
                      target="_blank"
                      href="https://aidsvu.org/">
                      <img
                        className="pb-3"
                        src={"/aidsvu-logo.png"}
                        alt="Image of AIDSVu.org Logo"
                      />
                      <p>
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          AIDSVu.org
                        </span>
                        As launched in partnership with Emory University’s
                        Rollins School of Public Health and the Center for AIDS
                        Research at Emory University (CFAR), AIDSVu.org is an
                        interactive mapping tool that helps users visualize HIV
                        data at the national, regional, and local levels, and
                        provides location services for HIV testing, prevention,
                        and care.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a
                      target="_blank"
                      href="https://www.gilead.com/purpose/partnerships-and-community/hiv-age-positively">
                      <img
                        className="pb-3"
                        src={"/hiv-age-positively-logo.png"}
                        alt="Image of HIV Age Positively®"
                      />
                      <p>
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">
                            HIV Age Positively
                          </span>
                          <sup>&reg;</sup>
                        </span>
                        Aims to support programs focused on improving the
                        quality of life and health of those aging with HIV, and
                        making an impact on HIV policy.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a
                      target="_blank"
                      href="https://radianhiv.org/">
                      <img
                        className="pb-3"
                        src={"/radian-logo.png"}
                        alt="Image of Radian® Logo"
                      />
                      <p>
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">RADIAN</span>
                          <sup>&reg;</sup>
                        </span>
                        A partnership between Gilead Sciences and the Elton John
                        AIDS Foundation to address new HIV infections and deaths
                        from HIV-related illnesses in Eastern Europe and Central
                        Asia.
                      </p>
                    </a>
                  </Col>
                </Row>

                <Row className="justify-content-between mb-4">
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a
                      target="_blank"
                      href="https://www.gilead.com/purpose/partnerships-and-community/transcend">
                      <img
                        className="pb-3"
                        src={"/transcend-logo.png"}
                        alt="Image of TRANScend®  Logo"
                      />
                      <p>
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">TRANScend</span>
                          <sup>&reg;</sup>
                        </span>
                        Supports trans-led organizations working to reduce the
                        impact of HIV within non-binary and transgender
                        communities.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0"
                  >
                    <a
                      target="_blank"
                      href="https://www.gileadcompass.com/">
                      <img
                        className="pb-3"
                        src={"/compass-initiative-logo.png"}
                        alt="Image of COMPASS Initiative® Logo"
                      />
                      <p>
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">
                            COMPASS Initiative
                          </span>
                          <sup>&reg;</sup>
                        </span>
                        (COMmitment to Partnership in Addressing HIV/AIDS in
                        Southern States): An over $100M, 10-year commitment to
                        help address HIV/AIDS in the US South.
                      </p>
                    </a>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0"
                  />
                </Row>
                <Row>
                  <Col xs={12} className="section mb-3 px-0">
                    <h3 className="title text-uppercase ">What's Ahead</h3>
                    <p>
                      To keep forward momentum on all of these causes, we’ve
                      created this website as an educational resource for
                      emerging leaders and advocates in the HIV community who
                      are engaged in our shared work to help end the HIV
                      epidemic.
                    </p>
                    <a href="/" className="font-black">
                      <span className="font-heavy pr-3 font-18">
                        Explore more
                      </span>
                    </a>
                    <i className="fa fa-chevron-right "></i>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="px-0">
          <Row>
            <Col xs={12} className="px-0">
              <BackToTop />
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
